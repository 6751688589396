<template>
	<div class="bgcard">
		<!-- <div class="searchlist">
			<div class="query">
				<div class="inputname questiontwo">
					<input v-model="parsm.keyword" :placeholder="$t('lang.Pleasebillnumber')"/>
				</div>
				<div class="searchbtn">
					<button @click="sear">
						<p style="color: #b2b2b2">{{ $t("lang.search") }}</p>
					</button>
				</div>
			</div>
		</div> -->
		<div class="serverfroms">
			<el-table :data="tableData" style="width: 100%">
				<template slot="empty">
					<div class="noCart">{{ $t("lang.tableNull") }}</div>
				</template>
				<el-table-column align="center" :label="$t('lang.paymentdays')" width="200">
					<template slot-scope="scope">
						<span>{{scope.row.createTime | times}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="email" align="center" :label="$t('lang.bankNumber')" width="250">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.time')" width="440">
					<template slot-scope="scope">
						<span>{{scope.row.endTime}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="media" align="center" :label="$t('lang.Mediafiles')" width="350">
				</el-table-column>
				<el-table-column prop="mediaName" align="center" :label="$t('lang.fileNames')" width="350">
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Changetype')" width="200">
					<template slot-scope="scope">
						<span v-if="scope.row.increment==1">{{$t('lang.reduce')}}</span>
						<span v-else>{{$t('lang.increase')}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Changedosage')" width="200">
					<template slot-scope="scope">
						<span>{{scope.row.amount |amoutsa}}GB</span>
					</template>
				</el-table-column>
				<el-table-column align="center" :label="$t('lang.Totalstorages')" width="200">
					<template slot-scope="scope">
						<span>{{scope.row.afterAmount |amoutsa}}GB</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="display: flex; align-items: center" v-if="tableData.length > 0">
				<div class="allNum">{{ $t("lang.total") }}: {{ settall }}</div>
				<el-pagination
					background
					:current-page="parsm.page"
					v-if="tableData.length > 0"
					layout="prev, pager, next"
					:total="settall"
					@current-change="handleChange"
					:prev-text="$t('lang.previouspage')"
					:next-text="$t('lang.nextpage')"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import * as flow from "@/api/order";
	export default{
		data() {
			return{
				tableData:[],
				settall:'',
				parsm:{
					page:1,
					size:10,
					etime:'',
					stime:'',
					keyword:'',
					email:''
				}
			}
		},
		created() {
			if(this.$route.query.etime){
				this.parsm.etime=this.$route.query.etime
				this.parsm.stime=this.$route.query.stime
				this.parsm.email=this.$route.query.email
			}
			this.lists()
		},
		filters:{
			times(val){
				let skr=''
				if(val){
					let sk=val.split(' ')
					let skk=sk[0].split('-')
					skr=skk[0]+'-'+skk[1]
				}
				return skr
			},
			amoutsa(val){
				if(val){
					return val.toFixed(4)
				}else{
					return val
				}
			},
		},
		methods:{
			// 搜索
			sear() {
				this.parsm.page = 1;
				this.lists();
			},
			//获取存储账单明细列表
			async lists(){
				let res=await flow.storagelist(this.parsm)
				this.tableData = res.data.content;
				this.settall = res.data.totalElements;
			},
			//分页页数改变
			handleChange(page){
				this.parsm.page=page
				this.lists()
			}
		}
	}
</script>

<style lang="less" scoped>
	.bgcard {
	  border: 0px;
	  box-sizing: border-box;
	  padding: 0.3rem;
	  border-radius: 0.02rem;
	  background: #222628;
	}
	.buttons {
	  // padding: 0px;
	  // width: 82px;
	  // height: 42px;
	  outline-style: none;
	  border: none;
	  border-radius: 0.04rem;
	  font-size: 0.16rem;
	  font-family: PingFang SC;
	  font-weight: 500;
	  color: #ffffff;
	  position: relative;
	}
	.messige {
	  position: absolute;
	  width: 0.1rem;
	  height: 0.1rem;
	  background: red;
	  top: 0.05rem;
	  left: 0.2rem;
	  border-radius: 50%;
	}
	.texts {
	  word-break: break-all;
	  text-overflow: ellipsis;
	  display: -webkit-box;
	  -webkit-box-orient: vertical;
	  -webkit-line-clamp: 2;
	  overflow: hidden;
	}
</style>
