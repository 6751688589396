import { render, staticRenderFns } from "./stordets.vue?vue&type=template&id=2507d619&scoped=true&"
import script from "./stordets.vue?vue&type=script&lang=js&"
export * from "./stordets.vue?vue&type=script&lang=js&"
import style0 from "./stordets.vue?vue&type=style&index=0&id=2507d619&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2507d619",
  null
  
)

export default component.exports